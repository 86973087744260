.eds-text-field {
  margin-top: 0px !important;
}

.customWidth {
  min-width: 100% !important;
  max-width: 100% !important;
}

.eds-input-message-group .eds-input-message-group__message {
  font-size: 12px;
}
