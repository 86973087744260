@media screen and (min-width: 576px) {
  .eds-modal__size-small .eds-modal {
    max-width: 500px !important;
  }
  .eds-modal__size-medium .eds-modal {
    max-width: 800px !important;
  }
  .eds-modal__size-large .eds-modal {
    min-width: 992px;
    max-width: 1440px !important;
  }
}
