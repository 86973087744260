html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--eds-color-grey-6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* restyle default date input */
input[type='date'],
.eds-input-box input[type='date'] {
  display: inline-flex;
  width: 180px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
