.layout {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: stretch;
}

.layout > .header {
  flex: 1 0 auto;
}

.layout > .content {
  width: 97.5%;
}

.layout > .nike-app-icon {
  width: 56px;
  height: 64px;
}
