.TableLayoutPagination {
  padding: var(--eds-space-16) var(--eds-space-16) var(--eds-space-8) var(--eds-space-16);
}

.TableLayoutPagination > div > Button {
  margin-right: var(--eds-space-4);
}

.MuiTableBody-root > .MuiTableRow-root:hover {
  background-color: var(--eds-color-background-hover);
}

.MuiTableCell-root span {
  font-weight: bold !important;
}

/*fixes grey background when modal is open*/
.eds-scrim {
  position: fixed !important;
}

.disabled {
  color: grey;
}

.MuiTableCell-root .TableSortIconDesc {
  margin: var(--eds-space-8) 0 0 var(--eds-space-4);
}

.MuiTableCell-root .TableSortIconAsc {
  margin: var(--eds-space-4) 0 0 var(--eds-space-4);
}

table.eds-table {
  overflow: clip;
  width: 100%;
}

.eds-table thead tr th {
  background-color: var(--eds-color-grey-3);
  position: sticky;
  top: 0;
}
